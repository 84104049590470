import { Estimate } from "./interfaces/Estimate";
import { UseGroup } from "./interfaces/UseGroup";
import { Alternate } from "./interfaces/Alternate";
import { LineItemData, LineItem } from "./interfaces/LineItem";
import { MarkupData, Markup } from "./interfaces/Markup";
import { EstimateRecord } from "./interfaces/EstimateRecord";
import { SortCode } from "./interfaces/SortCode";
import { SortField } from "./interfaces/SortField";
import { SortCodeItem, SortCodeMap } from "./interfaces/SortCodeMap";

interface Resource {
  id: string | null;
  type: "project" | "estimates" | "estimate" | "sort_codes" | "sort_fields" | "uf_codes" | "mf_codes";
}

const apiBaseUrl: string = "https://api.ediphi.com";

export const fetchAuthToken = async (idToken: string, tenant: string | null) => {
  const apiUrl = apiBaseUrl + "/api/projects";
  try {
    const apiResponse = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
        tenant: tenant ? tenant : "",
      },
    });

    if (!apiResponse.ok) {
      throw new Error(`API request failed with status ${apiResponse.status}`);
    }

    const xAuthToken = apiResponse.headers.get("x-auth");
    if (!xAuthToken) {
      throw new Error("x-auth header not found in the response.");
    }

    return xAuthToken;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error fetching x-auth token: ${error.message}`);
    } else {
      console.error("An unexpected error occurred during x-auth token fetch.");
    }
  }

  return null;
};

export const fetchData = async (xAuthToken: string, resource: Resource, tenant: string | null) => {
  let apiUrl: string;
  let method: string;

  if (resource.type == "project") {
    apiUrl = `${apiBaseUrl}/api/projects/${resource.id}`;
    method = "GET";
  } else if (resource.type == "estimates") {
    apiUrl = `${apiBaseUrl}/api/estimates?search=project%3B${resource.id}`;
    method = "GET";
  } else if (resource.type == "estimate") {
    apiUrl = `${apiBaseUrl}/api/estimates/${resource.id}/line_items/export`;
    method = "POST";
  } else if (resource.type == "sort_fields") {
    apiUrl = `${apiBaseUrl}/api/sort_fields`;
    method = "GET";
  } else if (resource.type == "sort_codes") {
    apiUrl = `${apiBaseUrl}/api/sort_codes`;
    method = "GET";
  } else if (resource.type == "uf_codes") {
    apiUrl = `${apiBaseUrl}/api/setup?search=key;sort_codes:uf`;
    method = "GET";
  } else if (resource.type == "mf_codes") {
    apiUrl = `${apiBaseUrl}/api/setup?search=key;sort_codes:mf`;
    method = "GET";
  } else {
    throw new Error("Invalid resource type");
  }

  if (!xAuthToken) {
    throw new Error("x-auth token is required to fetch data.");
  }

  try {
    const apiResponse = await fetch(apiUrl, {
      method: method,
      headers: {
        Authorization: `Bearer ${xAuthToken}`,
        tenant: tenant ? tenant : "",
      },
    });

    if (!apiResponse.ok) {
      throw new Error(`API request failed with status ${apiResponse.status}`);
    }

    return await apiResponse.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error fetching data: ${error.message}`);
    } else {
      console.error("An unexpected error occurred during data fetch.");
    }
  }
};

function searchCodeRecursive(
  item: SortCodeItem,
  codeToMatch: string,
  maxLevel: number,
  currentLevel: number
): { description: string } {

  // Ensure we don't get stuck in a loop
  if (currentLevel >= maxLevel) {
    return { description: "" };
  }
  
  // Check if the current item's code matches the search code
  if (item.code === codeToMatch) {
    return { description: item.description ? item.description : "" };
  }

  if (item.children) {
    for (const child of item.children) {
      const result = searchCodeRecursive(child, codeToMatch, maxLevel, currentLevel + 1);
      if (result.description !== "") {
        return result;
      }
    }
  }

  return { description: "" };
}

function getDescriptionForCode(
  rootItem: SortCodeItem,
  codeToMatch: string,
  maxLevel: number
): { description: string } {
  
  if (rootItem.value) {
    for (const item of rootItem.value) {
      const result = searchCodeRecursive(item, codeToMatch, maxLevel, 0);
      if (result.description !== "") {
        return result;
      }
    }
  }
  
  return { description: "" };
}

const flatten = <T>(xss: T[][]): T[] => {
  return xss.reduce((acc, xs) => acc.concat(xs), []);
};

const format = (str: string | null): string => {
  return str || "";
};

const createDataArray = (
  data: any, 
  sortCodesData: SortCode[], 
  sortFieldsData: SortField[], 
  ufCodes: SortCodeMap, 
  mfCodes: SortCodeMap
): Array<EstimateRecord> => {
  let records: Array<EstimateRecord> = [];

  const projectName: string = data.projectName || "";

  const estimate: Estimate = data.estimate || {};

  const alternates: Array<Alternate> = data.alternates || [];

  const useGroupsArray: Array<UseGroup> = (estimate.stats && estimate.stats.useGroups) || [];

  const useGroups: { [key: string]: UseGroup } = useGroupsArray.reduce(
    (acc: { [key: string]: UseGroup }, useGroup: UseGroup) => {
      acc[useGroup.id] = useGroup;
      return acc;
    },
    {}
  );

  const lineItems: Array<LineItem> = (data.line_items || []).map(
    (item: LineItemData) => new LineItem(item, alternates, useGroups)
  );

  const markups: Array<Markup> = (data.estimate_markups || []).map(
    (item: MarkupData) => new Markup(item, data.estimate_markups, alternates, estimate.stats)
  );

  const allUsedSortFields = Array.from(new Set(flatten(lineItems.map((item) => Object.keys(item.me.extras)))));

  const sortFields = (sortFieldsData || []).reduce((acc: any, field: any) => {
    acc[field.id] = field;
    return acc;
  }, {});

  const sortCodes = (sortCodesData || []).reduce((acc: any, code: any) => {
    acc[code.id] = code;
    return acc;
  }, {});

  const maxMf = Math.max(...lineItems.map((item) => item.sortLevel("mf")));

  const maxUf = Math.max(...lineItems.map((item) => item.sortLevel("uf")));
  try {
    for (const item of lineItems) {
      if (item.accepted() && item.me.parent === null) {
        const rec: EstimateRecord = {
          name: format(item.me.name),
          quantity: item.me.quantity,
          uom: item.me.uom,
          total_uc: item.me.total_uc,
          total: item.total(),
          type: "line_item",
          prod_rate: item.me.prod_rate || 0,
          labor_rate: item.me.labor_rate || 0,
          labor_uc: item.me.labor_uc || 0,
          material_uc: item.me.material_uc || 0,
          equip_uc: item.me.equip_uc || 0,
          alternate: format(item.altName()),
          use_group: item.sanitizedUseGroups(),
          project: projectName,
          estimate: estimate.name,
        };

        for (const ug of useGroupsArray) {
          const label = ug.label || ug.use_group;
          const alloc = (item.use_groups && ug.id in item.use_groups ? item.use_groups[ug.id] : 0) / 100;
          rec[label] = rec.total * alloc;
        }

        // MasterFormat
        for (let lvl = 1; lvl <= maxMf; lvl++) {
          const mfKey = `mf${lvl}`;
          const mfValue = item.me[mfKey] || "";
          rec[mfKey] = mfValue;

          const { description: mfDesc } = getDescriptionForCode(mfCodes, mfValue, lvl);

          rec[`${mfKey}_desc`] = mfDesc;
          rec[`${mfKey}_code_desc`] = `${mfValue}${mfValue && mfDesc ? " - " : ""}${mfDesc}`;
        }

        // Uniformat
        for (let lvl = 1; lvl <= maxUf; lvl++) {
          const ufKey = `uf${lvl}`;
          const ufValue = item.me[ufKey] || "";
          rec[ufKey] = ufValue;

          const { description: ufDesc } = getDescriptionForCode(ufCodes, ufValue, lvl);

          rec[`${ufKey}_desc`] = ufDesc;
          rec[`${ufKey}_code_desc`] = `${ufValue}${ufValue && ufDesc ? " - " : ""}${ufDesc}`;
        }

        for (const a of allUsedSortFields) {
          const sortFieldName = sortFields[a]?.name;
          const code = sortCodes[item.me.extras[a]]?.code || "";
          const desc = sortCodes[item.me.extras[a]]?.description || "";
          if (sortFieldName) {
            rec[`${sortFieldName}_code`] = code || "";
            rec[`${sortFieldName}_desc`] = format(desc);
            rec[`${sortFieldName}_code_desc`] = `${code}${code && format(desc) ? " - " : ""}${format(desc)}`;
          }
        }

        records.push(rec);
      }
    }

    for (const m of markups) {
      if (m.accepted()) {
        const rec: EstimateRecord = {
          name: format(m.desc),
          quantity: m.percent,
          uom: "%",
          total_uc: 0,
          total: m.total(),
          type: "markup",
          prod_rate: 0,
          labor_rate: 0,
          labor_uc: 0,
          material_uc: 0,
          equip_uc: 0,
          alternate: m.altName(),
          use_group: "",
          project: projectName,
          estimate: estimate.name,
        };

        for (const ug of useGroupsArray) {
          const label = ug.label || ug.use_group;
          const alloc = (ug.id in m.use_groups ? m.use_groups[ug.id] : 0) / 100;

          if (alloc === 0) {
            rec[label] =
              rec.total *
              (ug.costOfTradesSubtotal / (estimate.stats.costOfTradesSubtotal - estimate.stats.markupsTotal));
          } else {
            rec[label] = rec.total * alloc;
          }
        }

        records.push(rec);
      }
    }
  } catch (error) {
    console.error("Error transforming data or loading into sheet:", error);
  }

  records.push({ name: JSON.stringify(estimate.stats) } as EstimateRecord);
  return records;
};

export { createDataArray };
