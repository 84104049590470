/* global Excel */

export async function insertText(text: string) {
  // Write text to the top left cell.
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange("A1");
      range.values = [[text]];
      range.format.autofitColumns();
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}

// Helper Function to Convert Column Index to Excel Column Letters
function getColumnLetter(colIndex: number): string {
  let columnLetter = "";
  let dividend = colIndex + 1; // Excel columns are 1-indexed

  while (dividend > 0) {
    let modulo = (dividend - 1) % 26;
    columnLetter = String.fromCharCode(65 + modulo) + columnLetter;
    dividend = Math.floor((dividend - modulo) / 26);
  }

  return columnLetter;
}

export async function loadDataIntoSheet(rows: any) {
  const sheetName: string = "Data";
  const tableName: string = "estimateData";
  const startCell: string = "A1";

  try {
    if (rows.length === 0) {
      console.log("No rows to insert.");
      return;
    }

    const headers = Object.keys(rows[0]);

    await Excel.run(async (context) => {
      let sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
      await context.sync();

      if (sheet.isNullObject) {
        sheet = context.workbook.worksheets.add(sheetName);
      }

      let table = sheet.tables.getItemOrNullObject(tableName);
      await context.sync();

      if (!table.isNullObject) {
        const tableRange = table.getDataBodyRange();
        tableRange.clear();
        await context.sync();
        table.load("columns/items/name");
        await context.sync();

        const existingHeaders = table.columns.items.map((column) => column.name);

        // Remove columns that are not in the new headers
        for (let i = existingHeaders.length - 1; i >= 0; i--) {
          if (!headers.includes(existingHeaders[i])) {
            table.columns.getItem(existingHeaders[i]).delete();
          }
        }

        // Add new columns that are not in the existing headers
        for (const header of headers) {
          if (!existingHeaders.includes(header)) {
            table.columns.add(undefined, undefined, header);
          }
        }
        await context.sync();

        // Resize the table to include only the header row and one row of data
        const minimalRange = sheet.getRange(
          `${startCell}:${getColumnLetter(headers.length - 1)}${parseInt(startCell.match(/\d+/)![0]) + 1}`
        );
        table.resize(minimalRange);
        await context.sync();
      } else {
        // Create a new table if not exists
        const headerRange = sheet.getRange(
          `${startCell}:${getColumnLetter(headers.length - 1)}${startCell.match(/\d+/)![0]}`
        );
        headerRange.values = [headers];
        table = sheet.tables.add(headerRange, true);
        table.name = tableName;
        await context.sync();
      }

      if (rows.length > 0) {
        const dataStartCell = `${startCell.match(/[A-Z]+/)}${parseInt(startCell.match(/\d+/)![0]) + 1}`;
        const dataEndCell = `${getColumnLetter(headers.length - 1)}${rows.length + 1}`;
        const dataRangeAddress = `${dataStartCell}:${dataEndCell}`;

        // Ensure rows are arrays of values matching headers
        const dataToInsert = rows.map((row: any) => table.columns.items.map((header) => row[header.name]));
        const dataRange = sheet.getRange(dataRangeAddress);
                
        // Disable auto calculation before data is loaded.
        context.application.calculationMode = Excel.CalculationMode.manual;

        dataRange.values = dataToInsert;
      }

      await context.sync();
      console.log("Data loaded into sheet successfully.");
    });
  } catch (error) {
    console.error("Error transforming data or loading into sheet:", error);
  }
}
