/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from "react";
import { Image, tokens, makeStyles } from "@fluentui/react-components";

export interface HeaderProps {
  title: string;
  logo: string;
  message: string;
  isLoggedIn: boolean;
  initiateLogout: () => void;
}

const useStyles = makeStyles({
  welcome__header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "100px",
    position: "relative",
  },
  message: {
    fontWeight: "800",
    fontColor: "#060606",
    maxWidth: "400px",
    fontSize: "24px",
    lineHeight: "32px",
    textAlign: "center",
  },
  hamburgerMenu: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
  },
  dropdown: {
    position: "absolute",
    top: "40px",
    right: "10px",
    background: "#ffffff",
    color: tokens.colorNeutralForeground1,
    boxShadow: tokens.shadow8,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  dropdownButton: {
    padding: "10px",
    border: "none",
    background: "transparent",
    color: tokens.colorNeutralForeground1,
    cursor: "pointer",
    textAlign: "left",
    width: "100%",
  },
});

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, logo, message, isLoggedIn, initiateLogout } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const styles = useStyles();
  const headerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className={styles.welcome__header} ref={headerRef}>
      <button className={styles.hamburgerMenu} onClick={toggleDropdown}>
        &#9776;
      </button>
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <button className={styles.dropdownButton} onClick={initiateLogout}>
            Logout
          </button>
        </div>
      )}
      <Image width="90" height="90" src={logo} alt={title} />
      <h1 className={styles.message}>{message}</h1>
    </section>
  );
};

export default Header;
