import * as React from "react";
import { tokens, makeStyles } from "@fluentui/react-components";

export interface HeroListItem {
  icon: string;
  primaryText: string;
}

const useStyles = makeStyles({
  list: {
    listStyleType: "none",
    padding: 0,
    width: "100%",
    maxWidth: "400px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: "6px 10px",
    margin: "7.5px 0",
    lineHeight: "36px",
    textAlign: "left",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  icon: {
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconImage: {
    width: "24px",
    height: "24px"
  },
  itemText: {
    fontSize: "14px",
    color: "#000000",
    fontWeight: "400"
  },
  welcome__main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const HeroList: React.FC = () => {
  const items: HeroListItem[] = [
    {
      icon: "assets/instantly_export.svg",
      primaryText: "Instantly import test data into Excel",
    },
    {
      icon: "assets/customize_reports.svg",
      primaryText: "Customize your reports for clients",
    },
    {
      icon: "assets/refresh_data.svg",
      primaryText: "Refresh your data at any time",
    },
  ];
  const styles = useStyles();

  const listItems = items.map((item, index) => (
    <li className={styles.listItem} key={index}>
      <div className={styles.icon}>
        <img src={item.icon} alt="" className={styles.iconImage} />
      </div>
      <span className={styles.itemText}>{item.primaryText}</span>
    </li>
  ));

  return (
    <div className={styles.welcome__main}>
      <ul className={styles.list}>{listItems}</ul>
    </div>
  );
};

export default HeroList;
