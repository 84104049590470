/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Header from "./Header";
import HeroList from "./HeroList";
import { Button, Dropdown, makeStyles, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-components";
import { fetchData, fetchAuthToken, createDataArray } from "../services";
import { loadDataIntoSheet } from "../taskpane";
import { Project } from "../interfaces/Project";
import { Estimate } from "../interfaces/Estimate";
import { SortField } from "../interfaces/SortField";
import { SortCode } from "../interfaces/SortCode";
import { SortCodeMap } from "../interfaces/SortCodeMap";

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F0F3F6",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 20px"
  },
  spinner: {
    border: "4px solid rgba(128, 128, 128, 0.2)",
    borderTop: "4px solid #808080",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
  },
  button: {
    margin: "10px 0 10px 0",
    fontSize: "16px",
    width: "100%",
    maxWidth: "400px",
    borderRadius: "4px",
    color: "#FFFFFF",
    backgroundColor: "#0161E8",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#004FBE",
      color: "#FFFFFF"
    },
  },
  refreshButton: {
    margin: "10px 0 10px 0",
    fontSize: "16px",
    width: "100%",
    maxWidth: "400px",
    borderRadius: "4px",
  },
  messageLog: {
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    maxHeight: "100px",
    overflowY: "auto",
    width: "100%",
    maxWidth: "300px",
    wordWrap: "break-word",
  },
  tokenDisplay: {
    width: "100%",
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    maxWidth: "300px",
    wordWrap: "break-word",
  },
  apiResponseDisplay: {
    width: "100%",
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    backgroundColor: "#f1f1f1",
    wordWrap: "break-word",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
  },
  inputLabel: {
    width: "100%",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "700",
  },
  tenantInput: {
    width: "100%",
    textAlign: "left",
    border: "1px solid #ccc",
    height: "24px",
    borderRadius: "4px",
    margin: "2px 0 10px 0",
    backgroundColor: "none",
  },
  dropdown: {
    width: "100%",
    textAlign: "left",
    minWidth: "100px",
    height: "34px",
    border: "none",
    padding: "0",
    borderRadius: "4px",
    margin: "0 0 10px -10px",
    backgroundColor: "#fff",
    borderBottomWidth: "0",
    "::after": {
      borderBottomWidth: "0",
    },
  },
  dropdownInput: {
    width: "100%",
    padding: "5px 5px 15px 15px",
    textAlign: "left",
    height: "24px",
    borderRadius: "4px",
    margin: "2px 0 10px 0",
    backgroundColor: "none",
  },
});

const LoadingSpinner = () => {
  const classes = useStyles();
  return <div className={classes.spinner}></div>;
};

const App: React.FC<AppProps> = ({ title }) => {
  const styles = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [messageLog, setMessageLog] = useState<string[]>([]);
  const [token, setToken] = useState<string | null>(null);
  const [xAuthToken, setXAuthToken] = useState<string | null>(null);
  const [apiResponse, setApiResponse] = useState<string | null>(null);
  const [tenant, setTenant] = useState<string | null>(null);
  const [authUrl, setAuthUrl] = useState<string | null>(null);
  const [dev, setDev] = useState<boolean | null>(null);
  const [projectId, setProjectId] = useState<string>("");
  const [project, setProject] = useState<Project>({ projectName: "", projectId: "" });
  const [estimateId, setEstimateId] = useState<string>("");
  const [_estimate, setEstimate] = useState<Estimate>();
  const [estimates, setEstimates] = useState<Estimate[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [lastRefreshedAt, setLastRefreshedAt] = useState<string>(new Date().toLocaleString());
  const [loading, setLoading] = useState(false); 
  const [sortFields, setSortFields] = useState<SortField[]>([]);
  const [sortCodes, setSortCodes] = useState<SortCode[]>([]);
  const [ufCodes, setUfCodes] = useState<SortCodeMap>({});
  const [mfCodes, setMfCodes] = useState<SortCodeMap>({});

  useEffect(() => {
    const writeAndLoadSettingsFromWorkbook = async () => {
      try {
        await Office.onReady();

        // dummy values to use during development
        const tenantValueTemp: string = "mike";
        const authUrlValueTemp: string = "https://keycloak.ediphi.com";
        const devValueTemp: boolean = false;
        const projectIdValueTemp: string = "e5f1d556-59de-43d2-b313-87f638ddd9f0";

        await Excel.run(async (context) => {
          const workbook = context.workbook;

          // TODO refactor this part after we have the api writing these settings
          let settingsSheet = workbook.worksheets.getItemOrNullObject("Settings");
          await context.sync();

          if (settingsSheet.isNullObject) {
            settingsSheet = workbook.worksheets.add("Settings");

            // set values
            settingsSheet.getRange("A1").values = [["tenant"]];
            settingsSheet.getRange("A2").values = [[tenantValueTemp]];
            settingsSheet.getRange("B1").values = [["auth_url"]];
            settingsSheet.getRange("B2").values = [[authUrlValueTemp]];
            settingsSheet.getRange("C1").values = [["dev"]];
            settingsSheet.getRange("C2").values = [[devValueTemp]];
            settingsSheet.getRange("D1").values = [["projectId"]];
            settingsSheet.getRange("D2").values = [[projectIdValueTemp]];

            // Delete existing named ranges and add new ones
            const tenantNamedItem = workbook.names.getItemOrNullObject("Tenant");
            await context.sync();
            if (!tenantNamedItem.isNullObject) {
              tenantNamedItem.delete();
            }
            workbook.names.add("Tenant", settingsSheet.getRange("A2"));

            const authUrlNamedItem = workbook.names.getItemOrNullObject("AuthUrl");
            await context.sync();
            if (!authUrlNamedItem.isNullObject) {
              authUrlNamedItem.delete();
            }
            workbook.names.add("AuthUrl", settingsSheet.getRange("B2"));

            const devNamedItem = workbook.names.getItemOrNullObject("Dev");
            await context.sync();
            if (!devNamedItem.isNullObject) {
              devNamedItem.delete();
            }
            workbook.names.add("Dev", settingsSheet.getRange("C2"));

            const projectIdNamedItem = workbook.names.getItemOrNullObject("ProjectId");
            await context.sync();
            if (!projectIdNamedItem.isNullObject) {
              projectIdNamedItem.delete();
            }
            workbook.names.add("ProjectId", settingsSheet.getRange("D2"));

            await context.sync();
          }

          // Load values using named ranges
          const tenantRange = workbook.names.getItem("Tenant").getRange();
          const authUrlRange = workbook.names.getItem("AuthUrl").getRange();
          const devRange = workbook.names.getItem("Dev").getRange();
          const projectIdRange = workbook.names.getItem("ProjectId").getRange();

          tenantRange.load("values");
          authUrlRange.load("values");
          devRange.load("values");
          projectIdRange.load("values");
          await context.sync();

          const tenantValue = tenantRange.values[0][0];
          const authUrlValue = authUrlRange.values[0][0];
          const devValue = devRange.values[0][0];
          const projectIdValue = projectIdRange.values[0][0];

          if (!devValue) {
            settingsSheet.visibility = Excel.SheetVisibility.hidden; // TODO: set to veryHidden in production
          } else {
            settingsSheet.visibility = Excel.SheetVisibility.visible;
          }
          await context.sync();

          if (tenantValue && authUrlValue && projectIdValue) {
            setTenant(tenantValue);
            setAuthUrl(authUrlValue);
            setProjectId(projectIdValue);
            setDev(devValue);
            console.log(
              `Settings loaded successfully: {tenant: ${tenantValue}, auth_url: ${authUrlValue}, dev: ${devValue}, projectId: ${projectIdValue}}`
            );
          } else {
            console.error("Error: tenant or authUrl or projectId is undefined.");
            throw new Error("Settings not found");
          }
        });
      } catch (error) {
        console.error("Error writing and loading settings: " + error);
      }
    };

    writeAndLoadSettingsFromWorkbook();
  }, [tenant, authUrl, dev, projectId]);

  const clientId = "costlab";
  const redirectUri = encodeURIComponent(window.location.href);
  const responseType = "id_token";
  const scope = "openid";
  const nonce = Array.from(crypto.getRandomValues(new Uint8Array(16)))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  const addMessage = (message: string) => {
    setMessageLog((prevMessages) => [...prevMessages, message]);
  };

  const parseFragment = () => {
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    return params;
  };

  const extractToken = async () => {
    const params = parseFragment();
    const idToken = params.get("id_token");
    if (idToken) {
      setToken(idToken);
      try {
        const xAuth = await fetchAuthToken(idToken, tenant);
        setXAuthToken(xAuth);
        setIsLoggedIn(true);
        addMessage("Authentication successful.");
      } catch (error) {
        console.error("Error fetching x-auth token:", error);
      }
    } else {
      addMessage("No ID token found in the URL parameters.");
    }
  };

  const initiateLogin = () => {
    setLoading(true);
    addMessage("Redirecting to login...");
    const authUrlWithParams = authUrl
      ? `${authUrl}/realms/${tenant}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&nonce=${nonce}`
      : "";
    window.location.href = authUrlWithParams;
  };

  const initiateLogout = () => {
    addMessage("Logging out...");

    // Clear authentication state
    setToken(null);
    setIsLoggedIn(false);

    window.location.href = window.location.href.split("#")[0];
  };

  useEffect(() => {
    if (!authUrl) {
      return;
    }
    const params = parseFragment();
    if (params.has("id_token")) {
      extractToken();
    }
    setLoading(false);
  }, [authUrl]);

  useEffect(() => {
    if (isLoggedIn && xAuthToken && projectId) {
      addMessage("Fetching project data and estimate list...");

      const fetchAndLoadProjectData = async () => {
        try {
          const projectData = await fetchData(xAuthToken, { type: "project", id: projectId }, tenant);

          const project: Project = {
            projectName: projectData.data.name,
            projectId: projectData.data.id,
          };

          setProject(project);

          const filteredEstimates = await fetchData(xAuthToken, { type: "estimates", id: projectId }, tenant);

          if (filteredEstimates.length === 0) {
            throw new Error("No estimates found for the given project ID");
          }
          const estimates: Estimate[] = filteredEstimates.data;

          setEstimates(estimates);
          addMessage("Project data and estimate list fetched successfully.");

          const sortFields = await fetchData(xAuthToken, { type: "sort_fields", id: null }, tenant);
          if (sortFields.data.length === 0) {
            throw new Error("No sort fields found for the given tenant");
          }
          setSortFields(sortFields.data);

          const sortCodes = await fetchData(xAuthToken, { type: "sort_codes", id: null }, tenant);
          if (sortCodes.data.length === 0) {
            throw new Error("No sort codes found for the given tenant");
          }
          setSortCodes(sortCodes.data);

          const ufCodesData = await fetchData(xAuthToken, { type: "uf_codes", id: null }, tenant);
          if (ufCodesData.data.length === 0) {
            throw new Error("No Uniformat (UF) codes found for the given tenant");
          }
          setUfCodes(ufCodesData.data[0]);  // api w/ search param returns an single item array

          const mfCodesData = await fetchData(xAuthToken, { type: "mf_codes", id: null }, tenant);
          if (mfCodesData.data.length === 0) {
            throw new Error("No MasterFormat (MF) codes found for the given tenant");
          }
          console.log('mfcodedata', mfCodesData.data);
          setMfCodes(mfCodesData.data[0]);  // api w/ search param returns an single item array

        } catch (error: any) {
          addMessage(`Error fetching project data and estimate list: ${error.message}`);
        }
      };

      fetchAndLoadProjectData();
    }
  }, [isLoggedIn, xAuthToken, projectId]);

  const fetchAndLoadEstimateData = async (token: string) => {
    try {
      const estimateData = await fetchData(token, { type: "estimate", id: estimateId }, tenant);
      console.log("estimate data", estimateData);
  
      setEstimate(estimateData);
  
      try {
        const transformedEstimateData = createDataArray(estimateData, sortCodes, sortFields, ufCodes, mfCodes);
  
        setApiResponse(JSON.stringify(transformedEstimateData));
        addMessage("Estimate data fetched successfully.");
  
        await loadDataIntoSheet(transformedEstimateData);
        setLastRefreshedAt(new Date().toLocaleString());

        await Excel.run(async (context) => {
          const sheet = context.workbook.worksheets.getItem('data');
          const table = sheet.tables.getItem('estimateData');
        
          // Auto-fit all columns in the table
          table.columns.load('items');
          await context.sync();
        
          // Auto-fit all columns
          table.columns.items.forEach(column => {
            column.getRange().format.autofitColumns();
          });
        
          await context.sync();
        
          // Manually set the width of column A (first column in the table) to 400 pixels
          const firstColumnRange = table.columns.getItemAt(0).getRange();
          firstColumnRange.format.columnWidth = 400;
          await context.sync();

          // Create sort fields tab and lookup table dynamically
          table.columns.load("items/name");
          await context.sync();

          const formulaColumnPattern = /.*_code_desc/;
          let headers: string[][] = [];
          let formulaColumns: string[] = [];

          headers.push(["Use Group"]);
          formulaColumns.push("use_group");

          for (const col of table.columns.items) {
            if (formulaColumnPattern.test(col.name)) {
                formulaColumns.push(col.name);
                headers.push([col.name.split("_code_desc")[0]]);
            }
        }

          let sortFieldsSheet = context.workbook.worksheets.getItemOrNullObject("Sort Fields");
          await context.sync();

          // Populate sort fields tab
          if (sortFieldsSheet.isNullObject) {
            sortFieldsSheet = context.workbook.worksheets.add("Sort Fields");
            sortFieldsSheet.visibility = Excel.SheetVisibility.hidden;
            await context.sync();
          }
          const headerRange = sortFieldsSheet.getRangeByIndexes(0, 0, 1, headers.length);
          headerRange.values = [headers.flat()];

          for (let i = 0; i < formulaColumns.length; i++) {
            sortFieldsSheet.getRangeByIndexes(1, i, 1, 1).formulasR1C1 = [
              [`=UNIQUE(FILTER(estimateData[${formulaColumns[i]}], estimateData[${formulaColumns[i]}]<>0))`],
            ];
          }
          await context.sync();

          // Trigger manual recalculation and re-enable auto calculation after data is loaded.
          context.application.calculate(Excel.CalculationType.full);
          context.application.calculationMode = Excel.CalculationMode.automatic;
   
        });

      } catch (transformationError: any) {
        addMessage(`Error transforming data or loading into sheet: ${transformationError.message}`);
      }
    } catch (fetchError: any) {
      addMessage(`Error fetching estimate data: ${fetchError.message}`);
    }
  };
  
  const handleDataFetch = async () => {
    if (isLoggedIn && xAuthToken && estimateId) {
      addMessage("Fetching estimate data...");
      setRefreshKey((prevKey) => prevKey + 1);
      await fetchAndLoadEstimateData(xAuthToken);
    }
  };
  
  const handleButtonText = () => {
    return refreshKey > 0 ? "Refresh Data" : "Load Data"
  }
  const handleEstimateDropdownChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    const estimateIdValue = data.optionValue;
    if (!estimateIdValue) {
      console.error("Missing estimateId value");
      setEstimateId(estimateId);
      return;
    }
    setEstimateId(estimateIdValue);
    setRefreshKey(0);
  };
  const handleProjectDropdownChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    const projectIdValue = data.optionValue;
    if (!projectIdValue) {
      console.error("Missing projectId value");
      setProjectId(projectId);
      return;
    }
    setProjectId(projectIdValue);
  };
  const handleTenantInputChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    const tenantValue = data.optionValue;
    if (!tenantValue) {
      console.error("Missing tenant value");
      setTenant(tenant);
      return;
    }
    const regex = /^[A-Za-z0-9]+$/;
    if (!tenantValue.match(regex)) {
      console.error("Invalid tenant value format");
      setTenant(tenant);
      return;
    }
    setTenant(tenantValue);
  };
  const handleAuthURLDropdownChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    const authUrlValue = data.optionValue;
    if (!authUrlValue) {
      console.error("Missing AuthURL value");
      setAuthUrl(authUrl);
      return;
    }
    setAuthUrl(authUrlValue);
  };

  return (
    <div className={styles.root}>
      {loading ? ( <LoadingSpinner />
      ) : (
      <>
        <Header
          logo="assets/ediphi_logo.svg"
          title={title}
          message={xAuthToken ? "Linked Estimate" : "Seamlessly Sync Your Ediphi Data with Excel"}
          isLoggedIn={isLoggedIn}
          initiateLogout={initiateLogout}
        />
        {!isLoggedIn ? (
          <div className={styles.container}>
            <HeroList />
            {tenant && authUrl ? (
              <div className={styles.container}>
                <span className={styles.inputLabel}>Tenant</span>
                <Dropdown
                  placeholder="Select a tenant"
                  onOptionSelect={handleTenantInputChange}
                  className={styles.dropdown}
                  value={tenant}
                  appearance="filled-lighter"
                  title="Tenant"
                >
                  <Option key={tenant} value={tenant}>
                      {tenant}
                    </Option>
                </Dropdown>
                <label className={styles.inputLabel}>
                  Authenticating to <strong>{tenant}</strong> at:{" "}
                </label>
                <Dropdown
                  placeholder="Select an auth URL"
                  onOptionSelect={handleAuthURLDropdownChange}
                  className={styles.dropdown}
                  value={authUrl}
                  appearance="filled-lighter"
                  title="Auth URL Identifier"
                >
                  <Option key={authUrl} value={authUrl}>
                      {authUrl}
                    </Option>
                </Dropdown>
                <Button size="large" className={styles.button} onClick={initiateLogin}>
                  Login to Get Started
                </Button>
              </div>
            ) : (
              <p>Please load an Ediphi workbook to begin.</p>
            )}
          </div>
        ) : (
          <>
            <div className={styles.container}>
              <span className={styles.inputLabel}>Project</span>
              <div className={styles.dropdownInput}>
                <Dropdown
                  placeholder="Select a project"
                  onOptionSelect={handleProjectDropdownChange}
                  className={styles.dropdown}
                  appearance="filled-lighter"
                  title="Project Name"
                >
                  <Option key={projectId} value={projectId}>
                      {project.projectName}
                    </Option>
                </Dropdown>
              </div>
              <span className={styles.inputLabel}>Estimate</span>
              <div className={styles.dropdownInput}>
                <Dropdown
                  placeholder="Select an estimate"
                  onOptionSelect={handleEstimateDropdownChange}
                  className={styles.dropdown}
                  appearance="filled-lighter"
                >
                  {Array.isArray(estimates) &&
                    estimates.map((e) => (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    ))}
                </Dropdown>
              </div>
            </div>
            {estimateId ? (
              <Button appearance="primary" size="large" className={styles.refreshButton} onClick={handleDataFetch}>
                {handleButtonText()}
              </Button>
            ) : (
              <Button appearance="secondary" size="large" className={styles.refreshButton} disabled={true}>
                {handleButtonText()}
              </Button>
            )}
            <div className={styles.container}>{lastRefreshedAt ? `Last Refreshed At: ${lastRefreshedAt}` : ""}</div>
            {dev && (
              <>
                <div>
                  <br></br>
                  <br></br>
                  <strong>Dev Information</strong>
                </div>
                <div className={styles.messageLog}>
                  {messageLog.map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </div>
                <div className={styles.tokenDisplay}>
                  <strong>Bearer Token:</strong>{" "}
                  {token ? `${token.substring(0, 15)}...${token.substring(token.length - 15)}` : ""}
                </div>
                <div className={styles.tokenDisplay}>
                  <strong>X-Auth Token:</strong>{" "}
                  {xAuthToken ? `${xAuthToken.substring(0, 15)}...${xAuthToken.substring(xAuthToken.length - 15)}` : ""}
                </div>
                {apiResponse && (
                  <div className={styles.apiResponseDisplay}>
                    <strong>API Response:</strong>
                    {apiResponse}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    )}
    </div>
  );
};

export default App;
