import { Alternate } from "./Alternate";

export interface MarkupData {
  alternate: string | null;
  created_at: string;
  deleted_at: string | null;
  description: string;
  estimate: string;
  estimate_use_groups: { [key: string]: any };
  formula: string | null;
  overridden: boolean;
  parts: Array<{
    estimate_value: string;
    type: string;
  }>;
  total: number;
  id: string;
  order: number;
  percentage: number;
  updated_at: string;
  value: number | null;
}

export class Markup {
  me: MarkupData;
  estimate_stats: any;
  alternates: Alternate[];
  markups: MarkupData[];
  id: string;
  desc: string;
  order: number;
  percent: number;
  formula: any;
  use_groups: { [key: string]: any };
  alternate_id: string | null;

  constructor(data: MarkupData, markups: MarkupData[], alternates: Alternate[], estimate_stats: any) {
    this.me = data;
    this.estimate_stats = estimate_stats;
    this.alternates = alternates;
    this.markups = markups;
    this.id = data.id;
    this.desc = data.description;
    this.order = data.order;
    this.percent = data.percentage;
    this.formula = data.formula;
    this.use_groups = data.estimate_use_groups;
    this.alternate_id = data.alternate;
  }

  total(): number {
    if (this.formula === null) {
      const costOfTrades = this.estimate_stats.costOfTradesSubtotal - (this.estimate_stats.markupsTotal || 0);
      return (costOfTrades + this._allAboveMarkups()) * (this.percent / 100);
    } else if (this.formula.overridden) {
      return this.formula.total;
    }
    return this.formula.total * (this.percent / 100);
  }

  private _allAboveMarkups(): number {
    const markups = this.markups
      .filter((y) => y.order < this.order)
      .map((y) => new Markup(y, this.markups, this.alternates, this.estimate_stats));
    return markups.reduce((total, m) => total + m.total(), 0);
  }

  accepted(): boolean {
    if (this.alternate_id === null) {
      return true;
    }
    const status = this.alternates.find((y) => y.id === this.alternate_id)?.accepted;
    return status !== undefined ? status : false;
  }

  altName(): string {
    const name = this.alternates.find((y) => y.id === this.alternate_id)?.key;
    return name ? name : "";
  }
}
