import { Alternate } from "./Alternate";
import { UseGroup } from "./UseGroup";

export interface LineItemData {
  alternate: string | null;
  category: string | null;
  created_at: string;
  created_by: string;
  deleted_at: string | null;
  equip_uc: number;
  estimate: string;
  estimate_system: string | null;
  estimate_use_groups: { [key: string]: number };
  extras: { [key: string]: string };
  gcgr_line_item: string | null;
  gcgr_total_uc: number | null;
  id: string;
  import_batch: string | null;
  labor_rate: number;
  labor_uc: number;
  material_uc: number;
  name: string;
  notes: string | null;
  order: string;
  other_uc: number | null;
  parent: string | null;
  prod_rate: number | null;
  product: string | null;
  product_original: string | null;
  quantity: number;
  quantity_formula: string | null;
  selected_traditional_assembly: boolean;
  sub_uc: number | null;
  total_cost: number;
  total_cost_text: string | null;
  total_uc: number;
  type: string | null;
  uom: string;
  updated_at: string;
  updated_by: string;
  updated_by_at: string;
  [key: string]: any; // Allows dynamic keys like mf1, mf2, uf1, uf2
}

export class LineItem {
  me: LineItemData;
  alternates: Alternate[];
  estimate_use_groups: { [key: string]: UseGroup };
  use_groups: { [key: string]: number };

  constructor(data: LineItemData, alternates: Alternate[], useGroups: { [key: string]: UseGroup }) {
    this.me = data;
    this.alternates = alternates;
    this.use_groups = data.estimate_use_groups;
    this.estimate_use_groups = useGroups; // app level (not project level)
  }

  sanitizedUseGroups(): string {
    try {
      if (!this.use_groups || !this.estimate_use_groups) return "";

      const labels: string[] = [];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(this.use_groups)) {
        const use = this.estimate_use_groups[key];
        if (use) {
          const label = use.label && use.label !== "" ? use.label : use.use_group;
          labels.push(label);
        }
      }

      return labels.join(", ");
    } catch (error) {
      console.error("An error occurred in sanitizedUseGroups:", error);
      return "";
    }
  }

  total(): number {
    return this.me.total_cost_text ? parseFloat(this.me.total_cost_text) : this.me.quantity * this.me.total_uc;
  }

  accepted(): boolean {
    if (this.me.alternate === null) return true;
    const status = this.alternates.find((alt) => alt.id === this.me.alternate)?.accepted;
    return status !== undefined ? status : false;
  }

  altName(): string {
    const name = this.alternates?.find((alt) => alt.id === this.me.alternate)?.key;
    return name ? name : "";
  }

  sortLevel(type: string): number {
    try {
      if (type === "mf" || type === "uf") {
        const keys = Object.keys(this.me)
          .filter(key => key.startsWith(type))
          .map(key => parseInt(key.slice(type.length)));
        
        if (keys.length > 0) {
          return Math.max(...keys);
        }
      }
  
      if (type === "extras") {
        return Object.keys(this.me.extras).length;
      }
  
      return 0;
    } catch (error) {
      console.error(`Error calculating sortLevel for type ${type}:`, error);
      return 0;
    }
  }
}
